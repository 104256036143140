import React, { useEffect, useState } from "react";
import './styles/App.css';
import './styles/mint.css';
import myNft from './utils/MyNft.json';
import { ethers, providers } from "ethers";
import { Web3Provider } from "@ethersproject/providers";
import Modal from 'react-modal';
import { Link, useSearchParams } from "react-router-dom";

import {
    Web3ReactProvider,
    useWeb3React,
    UnsupportedChainIdError
} from "@web3-react/core";

import {
    NoEthereumProviderError,
    UserRejectedRequestError as UserRejectedRequestErrorInjected
} from "@web3-react/injected-connector";

import {
    URI_AVAILABLE,
    UserRejectedRequestError as UserRejectedRequestErrorWalletConnect
} from "@web3-react/walletconnect-connector";

import { useEagerConnect, useInactiveListener } from "./hooks";

import {
    injected,
    walletconnect,
    walletlink,
} from "./Connector/Connectors";

import { Spinner } from "./Spinner";

const ethereumAddressValidator = require("web3-utils").isAddress;

// CONFIGS TO KEEP
// ***************** FIX INDEX.HTML page details before LAUNCH!!!!!

let CONFIGS_DROPNAME = 'AI Basket Animals';  // used in ALTs and so forth
let CONFIGS_MINT_PRICE_CALC = 0.0075; // used for calculating
let CONFIGS_PROMO_PRICE_CALC = 0.0065; // used for calculating
/*
0.0075 == street price MINT PRICE (abt $11.50)                  WEI:  7500000000000000
0.0065 == promo price PROMO for customers of affils (abt $10)   WEI:  6500000000000000
0.0015 == commission each for affils (abt $2.30 each)           WEI:  1500000000000000
0.0050 == our share (abt $7.70 each)                            
*/
let CONFIGS_CURRENT_PRICE = CONFIGS_MINT_PRICE_CALC;
const CONFIGS_TOTALSUPPLY_CALC = 476; // used for calculating -- I think we may need this to calculate SOLD OUT
let CONFIGS_TOTALSUPPLY_DISPLAY = '476'; // used for display purposes only...
let CONFIGS_NFTS_RESERVED = 5; // I think we may need this to calculate SOLD OUT
let CONFIGS_WALLET_LIMIT = 10;
const CONFIGS_CONTRACT_ADDRESS = "0x5a420ac155554D7665E4c8FBF860c69301f586a2";
let CONFIGS_CHAIN_ID = 1; //set the configs chain id -- 1==MAINNET, 5==Goerli
let CONFIGS_CHAIN_DESCRIPTION = 'Mainnet'; // used in the ALERT
let CONFIGS_NETWORK = ''; // make either blank or 'goerli.' (with the period) -- used for link building only
const CONFIGS_JSON_METADATA_BASEURI = 'ipfs://QmeFhuW1LjDTP4zyiPrHfZCvz86ZBRHPYhFmsmfv3NiZ8Z/';
let CONFIGS_OPENSEA_URL = 'opensea.io'; // make either 'opensea.io' or 'testnets.opensea.io'
let CONFIGS_NFT_TITLE_SINGULAR = 'CARD';
let CONFIGS_NFT_TITLE_PLURAL = 'CARDS';
let CONFIGS_MERKLE_API = "https://standard-template-allowlist-api.netlify.app/.netlify/functions/express/api/"; //deployed merkle api
let CONFIGS_URLS_INSTAGRAM = '###';
let CONFIGS_URLS_TWITTER = 'https://twitter.com/GenNFTs';
let CONFIGS_URLS_OPENSEA_COLLECTION = 'https://'+ CONFIGS_OPENSEA_URL +'/collection/ai-basket-animals';
let CONFIGS_URLS_PROJECTWEBSITE = 'https://NFTgreetings.xyz/';
let CONFIGS_CONTRACT_URL = 'https://' + CONFIGS_NETWORK + 'etherscan.io/address/' + CONFIGS_CONTRACT_ADDRESS;
let CONFIGS_SHOW_COLLECTION = 0; // make 1 to show, 0 to NOT show NFT collection at bottom.
let NFTGREETINGS_LINKBACK1 = 'https://NFTGreetings.xyz/'
let NFTGREETINGS_LINKBACK2 = 'https://NFTGreetings.xyz/about.php'

// VARIOUS MESSAGES / STRINGS:
let CONFIGS_ALLOW_LIST_MESSAGE = 'Sorry, this is an allowlist-only minting period and your wallet is not on the list. Please return for the public mint, and/or chat with a team member if you believe this to be in error.';

let CONFIGS_BEGIN_MINT_ALERT = 'Minting now... please click Okay and wait while we write your mint to the blockchain. Another alert will popup shortly and let you know that the mint is complete.';

let CONFIGS_MINT_SUCCESS_ALERT = "You have successfully minted! Your NFT(s) should appear in your (or your recipient's) wallet and on OpenSea shortly.";

// VARIOUS ERRORS FROM SOLIDITY:
// fill this object with some terms that would be found from our solidity contract's errors,
// and then we can write custom responses for the alerts (but keep them all up here):
let SOLIDITY_ERROR_LIST = {
    1: {
        'error': '[error text snippet from wallet error]',
        'response': '[alert response]'
    },
    2: {
        'error': 'exceed the wallet limit',
        'response': 'Your transaction would exceed the wallet limit.'
    },
    3: {
        'error': 'exceed the max supply',
        'response': 'This drop is sold out. Check OpenSea for the secondary market.'
    },
    4: {
        'error': 'exceed max supply',
        'response': 'This drop is sold out. Check OpenSea for the secondary market.'
    },
    5: {
        'error': 'Sale is not active',
        'response': 'The sale has been disabled on the smart contract.'
    },
    6: {
        'error': 'Not enough ether sent',
        'response': 'You sent too little ETH for your purchase. If you feel this error is wrong, drop the team a note.'
    },
    7: {
        'error': 'User denied transaction',
        'response': 'The user has denied the current transaction.'
    },
    8: {
        'error': 'already claimed',
        'response': 'It looks like you have already claimed your allowlist NFTs.'
    },
    9: {
        'error': 'insufficient funds',
        'response': 'Insufficient funds. Please add enough ETH to your wallet for this NFT purchase + gas.'
    },
    10: {
        'error': 'are not allowlisted',
        'response': 'Sorry, you are not on the presale list. Please contact the team if you believe this to be an error.'
    }, 
    11: {
        'error': 'exceed the transaction',
        'response': 'Sorry, you cannot purchase that many NFTs in a single transaction during this mint.'
    }
}

let GENERIC_RESPONSE = 'Transaction canceled. Usually if you see this, it means that you have rejected a transaction. If you feel that this message displayed because of another error, please alert the devs and we will have a look.';

// ##################################################################################################
function floatify(number){
    return parseFloat((number).toFixed(10));
 }

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const connectorsByName = {
    Metamask: injected,
    WalletConnect: walletconnect,
    Coinbase: walletlink,
};

const connectorImagesByName = {
    Metamask: './MetaMask_Fox.svg.png',
    WalletConnect: './walletconnect.png',
    Coinbase: './coinbase-logo-freelogovectors.net_.png',
};

function getErrorMessage(error) {
    if (error instanceof NoEthereumProviderError) {
        return "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.";
    } else if (error instanceof UnsupportedChainIdError) {
        return "You're connected to an unsupported network.";
    } else if (
        error instanceof UserRejectedRequestErrorInjected ||
        error instanceof UserRejectedRequestErrorWalletConnect
    ) {
        return "Please authorize this website to access your Ethereum account.";
    } else {
        console.error(error);
        return "An unknown error occurred. Check the console for more details.";
    }
}

function getLibrary(provider, connector) {
    const library = new Web3Provider(provider);
    library.pollingInterval = 8000;
    return library;
}

export default function() {
    return (<Web3ReactProvider getLibrary = { getLibrary }>
        <App/>
        </Web3ReactProvider>
    )
};


const App = () => {

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [isValidAddress, setIsValidAddress] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const context = useWeb3React();
    const {
        connector,
        library,
        chainId,
        account,
        activate,
        deactivate,
        active,
        error
    } = context;

    // handle logic to recognize the connector currently being activated
    const [activatingConnector, setActivatingConnector] = useState();

    useEffect(() => {
        console.log('running')
        if (activatingConnector && activatingConnector === connector) {
            setActivatingConnector(undefined);
        }
    }, [activatingConnector, connector]);

    // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
    const triedEager = useEagerConnect();

    // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
    useInactiveListener(!triedEager || !!activatingConnector);

    const [mintCount, setMintCount] = useState(0);
    const [value, setValue] = useState(1);
    const [myNFTs, setMyNFTs] = useState([]);
    const [trackMintCount, setTrackMintCount] = useState(0);
    const [trackAnyMintCount, setTrackAnyMintCount] = useState(0);
    const [isSold, setIsSold] = useState(false);
    const [isLibrary, setIsLibrary] = useState(false);
    const [giftAddress, setGiftAddress] = useState('');
    const [ENSaddress, setENSaddress] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [mintPrice, setMintPrice] = useState(CONFIGS_CURRENT_PRICE); 
    const [linkBack1, setLinkBack1] = useState(NFTGREETINGS_LINKBACK1);
    const [linkBack2, setLinkBack2] = useState(NFTGREETINGS_LINKBACK2);

    let tokenId;
    let signer;
    let currentTotalSupply;

    const handleChange = (event) => {
        setValue(event.target.value);
        console.log("Mint amount = " + value);
    };

// *******************************************************************    
// BEGIN NEW RESOLVER CODE: replaces handleChangeAddress()
useEffect(() => {

    console.log('ENS GIFT ADDRESS: ' + giftAddress);

    let nameResolved = true; 

    const fetchResolveInfo = async() => {

        // if was blank...
        if ( giftAddress=="" ) { 
            setENSaddress('ERROR');
            setErrorMsg("🧑‍🎨 Wallet blank. Mint will go to YOU!");
            console.log("🤷 Wallet address field blank. (M01)");
            return;
        }

        // if not blank...
        const provider = library;
        let lowercaseGiftAddress = giftAddress.toLowerCase();
        if (lowercaseGiftAddress.includes('.eth')) {

            setENSaddress(lowercaseGiftAddress);

            var resolvedENSname = await provider.resolveName(giftAddress);
            console.log('RESOLVER INFO:'+ resolvedENSname);

            // it did not resolve and is bad (return 3):
            if ( resolvedENSname == null ) { 
                setENSaddress('ERROR');
                setErrorMsg("🛑 Invalid Wallet Address Entered");
                console.log("🛑 Invalid Wallet Address Entered (M02)");                
                return;
            }

            // if nothing changed, name ws good already (return 2):
            if ( resolvedENSname === giftAddress ) { 
                setErrorMsg("✅ Valid Wallet Address Entered");
                console.log("✅ Valid Wallet Address Entered (M03)");
                return;
            }

            // if got resolved, name is good, but set it first (return 2):
            if ( resolvedENSname !== giftAddress ) { 

                if (nameResolved) { 
                    // set the address as the gift address
                    setGiftAddress(resolvedENSname); 
                }
                setErrorMsg("✅ Valid Wallet Address Entered)");
                console.log("✅ Valid Wallet Address Entered (M04)");   
                return;

            }                 
        }

        if (ethereumAddressValidator((giftAddress.toLowerCase())) !== true) {
            setErrorMsg("🛑 Invalid Wallet Address Entered");
            console.log("🛑 Invalid Wallet Address Entered (M05)");
        } else {
            if (ENSaddress=='ERROR'){
                setErrorMsg("✅ Valid Wallet Address Entered");
                console.log("✅ Valid Wallet Address Entered (M06)");
            } else {
                setErrorMsg("🚀 Cool!, Will Mint to ENS Address: ➡️ "+ ENSaddress + " ⬅️");
                console.log("🚀 Cool!, Will Mint to ENS Address: ➡️ "+ ENSaddress + " ⬅️ (M07)");
            }
        }
        console.log("Gift Address = " + giftAddress + "M08");            

        return;

    } 

    fetchResolveInfo();

    return () => nameResolved = false;

}, [giftAddress]);

// END NEW RESOLVER CODE:    
// *******************************************************************    

    const setupLibrary = async() => {
        try {

            if (library) {
                setIsLibrary(true);
            }

            console.log("Setup Library");

        } catch (error) {
            console.log(error);
        }
    }

    const setupEventListener = async() => {

        try {
            const wallet = library;
            signer = wallet.getSigner();
            const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

            // event listener
            connectedContract.on("Mint", (from, tokenId) => {
                setTrackAnyMintCount((trackAnyMintCount) => trackAnyMintCount + 1);
                console.log(from, tokenId.toNumber());
            });

            // event listener
            connectedContract.on("MintGift", (from, tokenId) => {
                setTrackAnyMintCount((trackAnyMintCount) => trackAnyMintCount + 1);
                console.log(from, tokenId.toNumber());
            });

            if (mintCount >= (CONFIGS_TOTALSUPPLY_CALC - CONFIGS_NFTS_RESERVED)) {
                setMintCount(10000);
                setIsSold(true);
            }
            console.log('mintCount: ' + mintCount);

            console.log('EventListener...'+searchParams.get("ref"));

            let affiliateRef = searchParams.get("ref") || "";

                // But let's see if the affiliate is valid ...
                let chkAffiliate = await connectedContract.affiliateAccounts(affiliateRef);

                // a) Does he/she have a commission fee?
                let theCommission = chkAffiliate.affiliateFee;
                console.log('EventListener Affiliate fee is: ' + theCommission);

                // b) And is he/she activated?
                let isActive = chkAffiliate.affiliateIsActive   
                console.log('EventListener Affiliate activated? : ' + isActive);    

                if (theCommission && isActive) {
                    CONFIGS_CURRENT_PRICE = CONFIGS_PROMO_PRICE_CALC;  
                    setMintPrice(CONFIGS_CURRENT_PRICE);
                    setLinkBack1(NFTGREETINGS_LINKBACK1+'?ref='+affiliateRef);
                    setLinkBack2(NFTGREETINGS_LINKBACK2+'?ref='+affiliateRef);
                }

            if (library) {
                setIsLibrary(true);
            }

            console.log("Setup event listener!");

        } catch (error) {
            console.log(error);
        }
    }

    const askContractToMintNft = async() => {
        console.log(chainId);
        if (chainId !== CONFIGS_CHAIN_ID) {
            alert("Please connect to " + CONFIGS_CHAIN_DESCRIPTION);
            return;
        }

        try {
            const provider = library;
            const signer = provider.getSigner();
            const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

            let isAffiliate = false;
            console.log(searchParams);
            console.log(searchParams.get("ref"));
            let affiliateRef = searchParams.get("ref") || "";

            // Cast to lowercase because we don't want case to be a problem...
            // Affiliates can share refs like Jim, jim, JIM, etc. and it'll still
            // work because we are enforcing all lowercase on the contract side.
            affiliateRef = affiliateRef.toLowerCase();
            console.log('Affiliate ref is:'+affiliateRef);

            if(affiliateRef){

                // ok looks like an affiliate sale.
                isAffiliate = true;
                
                // But let's see if the affiliate is valid ...
                let chkAffiliate = await connectedContract.affiliateAccounts(affiliateRef);

                // a) Does he/she have a commission fee?
                let theCommission = chkAffiliate.affiliateFee;
                console.log('Affiliate fee is: ' + theCommission);

                // b) And is he/she activated?
                let isActive = chkAffiliate.affiliateIsActive   
                console.log('Affiliate activated? : ' + isActive);

                // If eitehr of those tests fail, we will not pass this along to the mint function
                // as an affiliate sale because it would throw a needless error. Instead, we will
                // pass it as just a non-commission sale. Kthx.
                if (theCommission==0 || isActive==false) { 
                    affiliateRef=''; isAffiliate = false; 
                    console.log('Mint passed to contract, but note that the user has likely come to a URL of an invalid affiliate because no fee structure was estabished on the smart contract and/or the affiliate was not activated on the contract.');
                }

                if (theCommission && isActive) {
                    isAffiliate = true;
                    CONFIGS_CURRENT_PRICE = CONFIGS_PROMO_PRICE_CALC;  
                    setMintPrice(CONFIGS_CURRENT_PRICE);
                }

            }
                
            console.log("Public Minting: Popping wallet open now.")
            console.log("Value: "+ value);

            // note: floatify This fixes Javascript's floating point math problem here...
            // see: https://stackoverflow.com/questions/588004/is-floating-point-math-broken
            let totalPrice = floatify(CONFIGS_CURRENT_PRICE * value);
            console.log('Total Price: ' + value + ' NFTs @ ' + CONFIGS_CURRENT_PRICE + ' ETH == ' + totalPrice + ' ETH');

            let nftTxn;

            if (giftAddress === '') {

                nftTxn = await connectedContract.sendNFT(value, account, isAffiliate, affiliateRef, {
                    value: ethers.utils.parseUnits(totalPrice.toString())
                });

            } else {

                if (!ethers.utils.isAddress(giftAddress)) {
                    alert("Invalid Address. Please enter again.");
                    setGiftAddress('');
                    return;
                }

                nftTxn = await connectedContract.sendNFT(value, giftAddress, isAffiliate, affiliateRef, {
                    value: ethers.utils.parseUnits(totalPrice.toString())
                });

            }

            console.log("Public Minting: Please wait...");

            alert(CONFIGS_BEGIN_MINT_ALERT);

            await nftTxn.wait();

            setGiftAddress('');

            alert(CONFIGS_MINT_SUCCESS_ALERT);

            //console.log("Minted, see transaction: https://" + CONFIGS_NETWORK + "etherscan.io/tx/" + nftTxn.hash);
            setTrackMintCount(trackMintCount + 1);

        } catch (e) {
            console.log(e)
            var error = e.toString().split(',');
            var rawErrorMessage = e.toString();
            let numSolidityErrors = Object.keys(SOLIDITY_ERROR_LIST).length;
            let errorFound = 0;

            // some canned responses from above:
            for (let i = 1; i <= numSolidityErrors; i++) {
                var targetString = SOLIDITY_ERROR_LIST[i].error;
                if (rawErrorMessage.search(targetString) > 1) {
                    let theMessage = SOLIDITY_ERROR_LIST[i].response;
                    errorFound++;
                    alert(theMessage);
                    console.log(theMessage);
                }
            }

            // or if no error was found yet:
            if (!errorFound) {

                alert(GENERIC_RESPONSE);
                console.log(rawErrorMessage);

            }

        }

    }


    const allowlistMint = async() => {
        console.log(chainId);
        if (chainId !== CONFIGS_CHAIN_ID) {
            alert("Please connect to " + CONFIGS_CHAIN_DESCRIPTION);
            return;
        }

        try {
            const provider = library;
            const signer = provider.getSigner();
            const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

            const walletAdd = await signer.getAddress();
            console.log(String(walletAdd));
            const requestOptions = {
                "referrerPolicy": "strict-origin-when-cross-origin",
                "body": null,
                "method": "GET",
                "mode": "cors",
                "credentials": "omit"
            }

            let res = await fetch(CONFIGS_MERKLE_API + String(walletAdd), requestOptions);
            console.log(res);

            let merkleObject = await res.json();
            console.log(merkleObject);

            if (!merkleObject.valid) {
                alert("Sorry, this is an allowlist-only minting period, and your wallet is not on the list. Please return for the public mint, and/or chat with a team member if you believe this to be in error.");
                return;
            }

            console.log("Allowlist Mint: Popping wallet now to pay gas.");
            console.log(value);

            let nftTxn = await connectedContract.allowlistMint(merkleObject.proof, value, {
                value: ethers.utils.parseUnits((CONFIGS_MINT_PRICE_CALC * value).toString())
            });

            console.log("Allowlist Mint: Please wait...");
            alert(CONFIGS_BEGIN_MINT_ALERT);

            await nftTxn.wait();
            alert(CONFIGS_MINT_SUCCESS_ALERT);

            console.log("Minted, see transaction: https://" + CONFIGS_NETWORK + "etherscan.io/tx/" + nftTxn.hash);
            setTrackMintCount(trackMintCount + 1);

        } catch (e) {

            console.log(e)
            var error = e.toString().split(',');
            var rawErrorMessage = e.toString();
            let numSolidityErrors = Object.keys(SOLIDITY_ERROR_LIST).length;
            let errorFound = 0;

            // some canned responses from above:
            for (let i = 1; i <= numSolidityErrors; i++) {
                var targetString = SOLIDITY_ERROR_LIST[i].error;
                if (rawErrorMessage.search(targetString) > 1) {
                    let theMessage = SOLIDITY_ERROR_LIST[i].response;
                    errorFound++;
                    alert(theMessage);
                    console.log(theMessage);
                }
            }

            // or if no error was found yet:
            if (!errorFound) {

                alert(GENERIC_RESPONSE);
                console.log(rawErrorMessage);

            }

        }

    }

    const getTotalNFTsMintedSoFar = async() => {

        try {

            const provider = library;
            const signer = provider.getSigner();
            const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

            console.log("Getting Mint Count")
            let mint_count = await connectedContract.totalSupply();
            console.log(ethers.utils.formatUnits(mint_count, 0));
            setMintCount(ethers.utils.formatUnits(mint_count, 0));

            console.log(`Set mint count is ${mint_count}`);

            console.log('Getting Minted NFTs');
            const userBalance = await connectedContract.balanceOf(account);
            console.log(userBalance);

        } catch (error) {
            console.log(error)
        }
    }

    const getTotalOwnerNFTs = async() => {

        if (CONFIGS_SHOW_COLLECTION == 1) {

            try {
                console.log("Getting total owner nfts");
                const provider = library;
                const signer = provider.getSigner();
                const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

                const userBalance = await connectedContract.balanceOf(account);
                console.log(userBalance);
                let arrNFT = []
                let data = null;

                for (let i = 0; i < userBalance; i++) {
                    console.log("entering collection for time: ", i);
                    tokenId = await connectedContract.tokenOfOwnerByIndex(account, i);
                    var openSeaLink = "https://" + CONFIGS_OPENSEA_URL + "/assets/" + CONFIGS_CONTRACT_ADDRESS + "/" + tokenId;

                    let metadataLink = CONFIGS_JSON_METADATA_BASEURI + tokenId;

                    await fetch(metadataLink)
                        .then(
                            function(response) {

                                if (response.status !== 200) {
                                    console.log('Error code: ' + response.status);
                                    return;
                                }

                                response.json().then(function(data) {

                                    //console.log(data);

                                    //console.log('CurrentID: ' + tokenId);
                                    var jsonRes = JSON.parse(JSON.stringify(data));
                                    var imageSrc = jsonRes.image;
                                    imageSrc = imageSrc.replace("ipfs://", "https://ipfs.io/ipfs/");
                                    data.image = imageSrc;
                                    data.openSeaLink = openSeaLink;
                                    //console.log('img src=' + imageSrc);
                                    arrNFT.push(data);
                                    setMyNFTs([...arrNFT]);
                                    let collectionImage = '<div class="collectionItem"><a target="blank" href="' + openSeaLink + '"><img class="collectionImage" src="' + imageSrc + '" /><div class="collectionTokenTitle">' + CONFIGS_NFT_TITLE_SINGULAR + ' #' + tokenId + '</div></a></div>';

                                });
                            }
                        )
                        .catch(function(err) {
                            console.log('Fetch Error :-S', err);
                        });

                }

                console.log(myNFTs);
                console.log(arrNFT);

            } catch (error) {
                console.log(error)
            }

        }

    }

    const onDisconnect = async() => {
        console.log("Killing the wallet connection", library);
        setIsLibrary(false);
        // disconnect wallet
        deactivate();
        console.log(account);
        console.log(library);
        console.log(connector);
    }

    useEffect(() => {
        setupLibrary();
    });

    useEffect(() => {
        if (library) { setupEventListener() };
    }, [isLibrary]);

    useEffect(() => {
        console.log(isLibrary);
        console.log(trackAnyMintCount);
        if (library) { getTotalNFTsMintedSoFar() }
    }, [isLibrary, trackAnyMintCount]);

    useEffect(() => {
        if (library) { getTotalOwnerNFTs() }
    }, [isLibrary, trackMintCount]);

    return (

        <>
        <div className = "App">

            { /* TOP BANNER */ } 
            <div className = "topBanner">
                <div className = "topBannerWrapper">
                    <div className = "topBannerLeft"> <img src="top-left-logo.jpg" alt="" /> </div>
                    <div className = "topBannerRight">
                        <a href={linkBack1} title="View all of our NFT greeting card options!"> Browse NFTGreetings Cards </a>   
                        <a href={CONFIGS_URLS_OPENSEA_COLLECTION} title="View this collection on OpenSea">View Collection on OpenSea</a>
                        <a href={linkBack2} title="About NFTGreetings.xyz, where you can send web3 greeting card NFTs!"> About NFTGreetings.xyz </a> 
                    </div> 
                </div>
            </div>

            <div className = "clear"> </div>

            <div className = "mintBoxWrapper">

                <div className = "mintBoxLeft"> 
                
                    <img className="leftgif" src="feature-gif.gif" />

                    <p>Each NFT card is a <span className = "pageHighlightColor">unique AI-generation</span>.</p>
                    <p>Stored as <span className = "pageHighlightColor">Ethereum-based NFTs</span>.</p>
                    <p>Connect your wallet and <span className = "pageHighlightColor">send to your recipient's wallet</span> (or mint to your own wallet).</p>
                    <p><span className = "pageHighlightColor">Limited, unique, and collectible!</span></p>
                    <p><a href="https://NFTgreetings.xyz/" target="_blank"><span className = "pageHighlightColor">NFTGreetings.xyz</span></a> is powered by <a href="https://generativenfts.io/" target="_blank"><span className = "pageHighlightColor">GenerativeNFTs.io</span></a>, web3 tech developers, generative NFT coders, and NFT strategy experts.</p>

                    { 
                        (active === true) ? 

                        (
                            <>
                            <hr></hr>
                            <p><span className = "pageHighlightColor">NOTE:</span> After minting, if the NFT does not appear in your recipient's wallet on OpenSea, <a href="https://nftgreetings.xyz/nftfaqs.php" className="pageHighlightColor">click here</a> to view our FAQ on how to have it revealed. (OpenSea sometimes places NFTs in a "hidden" folder.)</p>
                            </>
                        ) : (
                            <></>
                        )

                    }
                    
                </div>

                <div className = "mintBoxRight">

                    <div id = "DAppArea">

                        { /* PUBLIC SALE HEADER -- UPDATE FOR PRESALE / PUBLC SALE!!!!!!! */ } 
                        <h2 style = { { marginBottom: 0 } }> 
                            <span className = "pageHighlightColor">AI BASKET ANIMALS: THANK YOU</span>
                        </h2 >

                        <p>This collection features AI-generated animals and baskets. Sometimes the animals are inside the baskets, and sometimes they are next to the baskets. But all are cuteness overload and a perfect way to deliver a thank-you to someone.</p>
                        
                        <p>To send one to your recipient, enter their wallet address below and your greeting card will be delivered into their wallet. (If you do not indicate a wallet add, the card will be minted to your wallet.)</p>

                        {
                            (active === false) ? (

                                <div id = "prepare">

                                    <p><br/><b> Note: </b> If you do not have a wallet installed, you will not see a "Connect Wallet" button.<br/></p>

                                    <button onClick = { openModal } className="btn-primary pageButton" id="connectButton" type="button" >Connect Wallet</button> 

                                    <Modal isOpen = { modalIsOpen } onAfterOpen = { afterOpenModal } onRequestClose = { closeModal } style = { customStyles } contentLabel = "Wallet Connection"> 

                                        <h2 ref = { (_subtitle) => (subtitle = _subtitle) }>Choose Wallet Provider</h2>
                                                                                
                                        {
                                            Object.keys(connectorsByName).map(name => {
                                                const currentConnector = connectorsByName[name];
                                                const activating = currentConnector === activatingConnector;
                                                const connected = currentConnector === connector;

                                                return (
                                                    <button className="providerChoices" key={ name } onClick = { () => {
                                                            setActivatingConnector(currentConnector);
                                                            activate(connectorsByName[name]);
                                                            closeModal(); }}>
                                                        <div style = { { position: "absolute", top: "0", left: "0", height: "100%", display: "flex", alignItems: "center", color: "black", margin: "0 0 0 1rem" } }> { activating && ( <Spinner color={"black"} style={{height:"25%", marginLeft: "-1rem" } } /> ) }
                                                        </div> 
                                                        <img className='logo-wallet' src={ connectorImagesByName[name] } alt="" /> 
                                                        <span className="walletName" > { name } </span>
                                                    </button>
                                                );

                                            })
                                        }

                                </Modal>

                                </div>

                            ):(

                                (isSold === false) ? (

                                    <div>

                                        <div id="workingTable">

                                            <table>
                                                <tbody>

                                                    {/*
                                                    <tr>
                                                        <td><b>Total Supply:</b><br/>&nbsp;</td>
                                                        <td>
                                                            { CONFIGS_TOTALSUPPLY_DISPLAY } NFTs, each programmatically unique!<br/>&nbsp;
                                                        </td>
                                                    </tr>
                                                    */}

                                                    <tr>
                                                        <td><b>Quantity Desired:</b></td>
                                                        <td>
                                                            <div className="theSlider"><input id="sliderVal" className= "slider" onChange= { handleChange } max= { CONFIGS_WALLET_LIMIT } min="1" type="range" value= { value }/></div>
                                                        </td>
                                                    </tr>


                                                    <tr>
                                                        <td><b>Send To:</b></td>
                                                        <td>
                                                        <div><input id="walletInput" type="text" value = { giftAddress } onChange = {e => setGiftAddress(e.target.value)} placeholder = "Enter ETH wallet address of recipient." /> {/* <br/><span className="smaller">(Leave blank to mint to yourself. ENS names will auto-resolve here.)</span>*/} <p>{ errorMsg }</p></div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td><b>ETH Required:</b></td>
                                                        <td>
                                                            <div id = "totalPrice" > @ {(CONFIGS_CURRENT_PRICE * value).toFixed(4) } ETH + gas </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td><b>Let's Go!</b></td>
                                                        <td>
                                                            <button onClick = { askContractToMintNft } className = "btn-custom btn-primary pageButton" type = "button" id = "theMintButton" > Mint / Send { value } { (value < 2) ? CONFIGS_NFT_TITLE_SINGULAR: CONFIGS_NFT_TITLE_PLURAL }</button>                                                        
                                                        </td>
                                                    </tr>

                                                    {/*
                                                    <tr>
                                                        <td><b>Card Stats:</b></td>
                                                        <td>
                                                            <p>< span id = "currentMintCount" > { mintCount }</span>{ ' ' } / { ' ' }{ CONFIGS_TOTALSUPPLY_DISPLAY } sent so far!</p>
                                                        </td>
                                                    </tr>
                                                    */}

                                                </tbody>
                                            </table>

                                        </div>

                                        {/* 
                                            <p style = { { marginBottom: 0 } } > Select Quantity { ' ' }(Limit { CONFIGS_WALLET_LIMIT } { ' ' }/ wallet):</p>
                                                                                    
                                            PRESALE MINT BUTTON
                                            <button onClick = { allowlistMint } className = "btn-custom btn-primary pageButton" type = "button" id = "theMintButton" > Mint { value } { (value < 2) ? CONFIGS_NFT_TITLE_SINGULAR: CONFIGS_NFT_TITLE_PLURAL
                                            } </button>

                                        */ }

                                        <div id = "connected" >

                                            <button onClick = { onDisconnect } className = "btn btn-primary pageButton" id = "disconnectButton"
                                            type = "button" > Disconnect < span className = 'qty smaller fixcaps' > 0x { String(account).slice(2, 5) + "..." + String(account).slice(-4) } </span></button>

                                            {/*
                                            <p className = "buttonMessage" > After minting, head to <a target = "_blank" href = { 'http://opensea.io/' + account } > your wallet on OpenSea </a> to view.</p>
                                            */}

                                            <p className="bottomMessageMobile"><span className = "pageHighlightColor">NOTE:</span> After minting, if the NFT does not appear in your recipient's wallet on OpenSea, <a href="https://nftgreetings.xyz/nftfaqs.php" className="pageHighlightColor">click here</a> to view our FAQ on how to have it revealed. (OpenSea sometimes places NFTs in a "hidden" folder.)</p>

                                        </div>

                                    </div>

                                ) : (

                                    <div id = "totalPrice">THIS CARD IS OUT OF STOCK<br/><a href= { CONFIGS_URLS_OPENSEA_COLLECTION }>Have a look on OpenSea</a> to grab a rare on the secondary market.</div>

                                )

                            )
                        }

                        <p><span className = "soldFont" id = "amountSold" > </span></p>

                    </div> {/** DAppArea */}

                </div> {/** mintboxright */}

            </div> {/** mintboxwrapper */}

            <div className ="clear"> </div>

            {

                (CONFIGS_SHOW_COLLECTION == 1) ? (

                        <div>

                            <div className="collectionWrapper">

                                <h2>Your NFTs From This Collection</h2>

                                <p style={{ maxWidth: "80%", marginLeft: "auto", marginRight: "auto", fontSize: "125%", padding: "20px" }}> After purchasing NFTs from this collection, your NFTs will be written to the blockchain and will then appear below.They will also link to OpenSea, where you will be able to view NFT properties(once revealed), view the collection, and buy / sell / trade NFTs.</p>

                                <div className="collectionRow" id="renderCollection">

                                    {myNFTs.map((myNFT) => <div key={myNFT.name} className="collectionItem">
                                        <a target="_blank" rel="noreferrer" href={myNFT.openSeaLink}>
                                            <img className="collectionImage" src={myNFT.image} alt="" />
                                            <div className="collectionTokenTitle">
                                                <p>{myNFT.name}</p>
                                            </div>
                                        </a>
                                    </div>
                                    )}

                                </div>

                            </div>

                        </div>

                ) : ( 
                
                    <div className = "noCollectionSpacer" > </div> 
                
                )

            }

            <div className = "clear"> </div>

            <div>

                <div className = "footer">

                    PROJECT LINKS: &nbsp;&nbsp;&nbsp;&nbsp;

                    <a className="pageButton marginright" target="_blank" rel="noreferrer" href="https://www.NFTGreetings.xyz" title="NFTGreetings.zyz Home"><img src="logo-greetings.png" alt="NFTGreetings.zyz Home" /></a> 

                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_TWITTER } title="Get project updates on Twitter from GenerativeNFTs."><img src="logo-twitter.png" alt="Get project updates on Twitter from GenerativeNFTs." /></a> 

                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_OPENSEA_COLLECTION } title="View this collection on OpenSea."><img src="logo-opensea.png" alt="View this collection on OpenSea." /></a> 

                    <a className="pageButton marginright" target="_blank" rel="noreferrer" href={ CONFIGS_CONTRACT_URL } title="View the verified smart contract for this project here."><img src="logo-etherscan.png" alt="View the verified smart contract for this project here." /></a> 

                    <a className="pageButton marginright" target="_blank" rel="noreferrer" href="https://www.GenerativeNFTs.io" title="Powered by GenerativeNFTs.io"><img src="logo-gennft.png" alt="Powered by GenerativeNFTs.io" /></a> 

                </div>

            </div>
        
        </div>

        <div className = "clear"> </div>
        </>
        
    );
};